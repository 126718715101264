import React from "react"
import NavBar from "../components/navBar/NavBar"
import Footer from "../components/footer/Footer"

// import styles from "./Layout.module.scss"

type LayoutProps = {
  children: React.ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  return (
    <>
      <NavBar />
      <main id="main">{children}</main>
      <Footer />
    </>
  )
}

export default Layout
