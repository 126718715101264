import React from "react"
import logo from "../../assets/brand/logo.svg"
import style from "./NavBar.module.scss"

const NavBar: React.FC = () => (
  <nav className={style.nav}>
    <div id="nav-logo">
      <img
        src={logo}
        alt="Charles Dudley"
        onClick={() => location.assign("mailTo: info@charlesdudleygardens.com")}
      />
    </div>
  </nav>
)

export default NavBar
