import React from "react"
import logo from "../../assets/brand/logo-subtitled.svg"
import phoneIcon from "../../assets/icons/phone.svg"
import mailIcon from "../../assets/icons/mail.svg"
import instagramIcon from "../../assets/icons/instagram.svg"

import styles from "./Footer.module.scss"

const Footer: React.FC = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.grid}>
        <div>
          <img src={logo} alt="Charles Dudley Gardens and Landscapes" />
        </div>
        <div>
          <p>
            Charles Dudley offer high-end garden services to commercial and
            domestic sites across London. We help our partners create and
            maintain beautiful spaces. Offering cost-effective garden
            maintenance contracts and one off visits at your convenience.
          </p>
        </div>
      </div>
      <div>
        <div className={styles.links}>
          <div>
            <a href="mailto:info@charlesdudleygardens.com">
              <img src={mailIcon} alt="Mail Charles Dudley" />
              info@charlesdudleygardens.com
            </a>
            <a href="tel:0203 6338 069">
              <img src={phoneIcon} alt="Telephone Charles Dudley" />
              0203 6338 069
            </a>
            <a href="https://www.instagram.com/charles_dudley_gardens/">
              <img src={instagramIcon} alt="Charles Dudley on Instagram" />
              Follow
            </a>
          </div>
          <span>
            © {new Date().getFullYear()} Charles Dudley Limited |{" "}
            <a href="https://loopspeed.co.uk" target="_blank" rel="noreferrer">
              Website by Loopspeed
            </a>
          </span>
        </div>
      </div>
    </footer>
  )
}

export default Footer
